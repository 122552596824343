import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button, Modal, Form, Table } from 'react-bootstrap'
import { FaRegEdit, FaTrashAlt } from 'react-icons/fa'
import Swal from 'sweetalert2'
import { Constants } from 'src/Constants'

const SubCategoryList = () => {
  const [subcategories, setSubcategories] = useState([])
  const [categories, setCategories] = useState([])
  const [showAddModal, setShowAddModal] = useState(false)
  const [newSubcategory, setNewSubcategory] = useState({
    category: '',
    name: '',
  })
  const [showEditModal, setShowEditModal] = useState(false)
  const [editedSubcategory, setEditedSubcategory] = useState({
    _id: '',
    category: '',
    name: '',
  })

  useEffect(() => {
    // Fetch all subcategories
    fetch(Constants.getAllSubCategory)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 1) {
          setSubcategories(data.subcategories)
        }
      })
      .catch((error) => console.error('Error fetching subcategories:', error))

    // Fetch all categories
    fetch(Constants.getAllCategory)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 1) {
          setCategories(data.categories)
        }
      })
      .catch((error) => console.error('Error fetching categories:', error))
  }, [])

  const handleAddClose = () => setShowAddModal(false)
  const handleAddShow = () => setShowAddModal(true)

  const handleChange = (e) => {
    const { name, value } = e.target
    setNewSubcategory({
      ...newSubcategory,
      [name]: value,
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const response = await fetch(Constants.createSubCategory, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newSubcategory),
      })

      if (response.ok) {
        const data = await response.json()
        if (data.status === 1) {
          // Refresh subcategories after adding a new one
          handleAddClose()
          setNewSubcategory({
            category: '',
            name: '',
          })
          // Fetch subcategories again
          const subcategoryResponse = await fetch(Constants.getAllSubCategory)
          const subcategoryData = await subcategoryResponse.json()
          if (subcategoryData.status === 1) {
            setSubcategories(subcategoryData.subcategories)
          }
          Swal.fire({
            icon: 'success',
            title: 'Subcategory added successfully',
          })
        } else {
          throw new Error('Failed to add subcategory')
        }
      } else {
        throw new Error('Failed to add subcategory')
      }
    } catch (error) {
      console.error('Error adding subcategory:', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Failed to add subcategory!',
      })
    }
  }
  const handleDelete = async (id) => {
    // Show a confirmation dialog before deleting the subcategory
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this subcategory!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await fetch(`${Constants.deleteSubCategoryByID}/${id}`, {
            method: 'DELETE',
          })

          if (response.ok) {
            // Filter out the deleted subcategory from the state
            setSubcategories(subcategories.filter((subcategory) => subcategory._id !== id))
            Swal.fire({
              icon: 'success',
              title: 'Subcategory deleted successfully',
            })
          } else {
            throw new Error('Failed to delete subcategory')
          }
        } catch (error) {
          console.error('Error deleting subcategory:', error)
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Failed to delete subcategory!',
          })
        }
      }
    })
  }

  const handleEditShow = (id) => {
    // Fetch subcategory by ID
    fetch(`${Constants.getSubCategoryByID}${id}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch subcategory')
        }
        return response.json()
      })
      .then((data) => {
        if (data.status === 1) {
          setEditedSubcategory(data.subcategory)
          setShowEditModal(true)
        } else {
          throw new Error('Subcategory not found')
        }
      })
      .catch((error) => console.error('Error fetching subcategory by ID:', error))
  }

  const handleEditClose = () => {
    setShowEditModal(false)
  }

  // Function to handle changes in the edit modal form fields
  const handleEditChange = (e) => {
    const { name, value } = e.target
    setEditedSubcategory({
      ...editedSubcategory,
      [name]: value,
    })
  }

  const handleSubmitEdit = async (e) => {
    e.preventDefault()
    try {
      const response = await fetch(`${Constants.editSubCategoryByID}${editedSubcategory._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          category: editedSubcategory.category,
          name: editedSubcategory.name,
        }),
      })

      if (response.ok) {
        // Refresh subcategories after editing
        handleEditClose()
        const subcategoryResponse = await fetch(Constants.getAllSubCategory)
        const subcategoryData = await subcategoryResponse.json()
        if (subcategoryData.status === 1) {
          setSubcategories(subcategoryData.subcategories)
        }
        Swal.fire({
          icon: 'success',
          title: 'Subcategory updated successfully',
        })
      } else {
        throw new Error('Failed to update subcategory')
      }
    } catch (error) {
      console.error('Error updating subcategory:', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Failed to update subcategory!',
      })
    }
  }
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="mb-0 text-info">SUB CATEGORY LIST</h4>
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li className="breadcrumb-item active text-info">Sub Category List </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="row m-2">
          <div className="col-xl-12">
            <div className="card custom-shadow rounded-lg border">
              <div className="card-body">
                <div className="">
                  <div className="dataTables_length">
                    <div></div>
                    <div className="search-box">
                      <Button variant="primary" className="my-2" onClick={handleAddShow}>
                        Add New Sub Category
                      </Button>
                      <Modal show={showAddModal} onHide={handleAddClose}>
                        <Modal.Header closeButton>
                          <Modal.Title>Add New Sub Category</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="category">
                              <Form.Label>Category</Form.Label>
                              <Form.Control
                                as="select"
                                name="category"
                                onChange={handleChange}
                                value={newSubcategory?.category}
                              >
                                <option value="">Select category</option>
                                {categories.map((category) => (
                                  <option key={category?._id} value={category?.name}>
                                    {category?.name}
                                  </option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                            <Form.Group controlId="name">
                              <Form.Label>Subcategory Name</Form.Label>
                              <Form.Control
                                type="text"
                                name="name"
                                value={newSubcategory.name}
                                onChange={handleChange}
                              />
                            </Form.Group>
                            <Button variant="primary" type="submit" style={{ marginTop: '10px' }}>
                              Add Subcategory
                            </Button>
                          </Form>
                        </Modal.Body>
                      </Modal>
                    </div>
                  </div>

                  <Table responsive bordered hover>
                    <thead>
                      <tr>
                        <th>Sub Category ID</th>
                        <th>Sub Category Name</th>
                        <th> Category Name</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {subcategories.map((subcategory) => (
                        <tr key={subcategory?._id}>
                          <td>{subcategory?._id}</td>
                          <td>{subcategory?.name}</td>
                          <td>{subcategory?.category?.name}</td>
                          <td>
                            <Button
                              variant="warning"
                              className="btn btn-sm btn-warning mr-2 mx-2"
                              onClick={() => handleEditShow(subcategory?._id)}
                            >
                              <FaRegEdit />
                            </Button>
                            <button
                              className="btn btn-sm btn-danger"
                              onClick={() => handleDelete(subcategory?._id)}
                            >
                              <FaTrashAlt />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                  <div className="container"></div>
                </div>
              </div>
            </div>
            <Modal show={showEditModal} onHide={handleEditClose}>
              <Modal.Header closeButton>
                <Modal.Title>Edit Subcategory</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form onSubmit={handleSubmitEdit}>
                  {/* <Form.Group controlId="editCategory">
                    <Form.Label>Category</Form.Label>
                    <Form.Control
                      as="select"
                      name="category"
                      value={editedSubcategory.category}
                      onChange={handleEditChange} // Make sure to use handleEditChange for onChange event
                    >
                      <option value="">Select category</option>
                      {categories.map((category) => (
                        <option key={category._id} value={category.name}>
                          {category.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group> */}
                  <Form.Group controlId="editName">
                    <Form.Label>Subcategory Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={editedSubcategory.name}
                      onChange={handleEditChange}
                    />
                  </Form.Group>
                  <Button variant="primary" type="submit" style={{ marginTop: '10px' }}>
                    Update Subcategory
                  </Button>
                </Form>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SubCategoryList
