import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { FaEye, FaRegEdit, FaTrashAlt } from 'react-icons/fa'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  InputGroup,
  FormControl,
  Spinner,
} from 'react-bootstrap'
import { Constants } from 'src/Constants'

const MySwal = withReactContent(Swal)

const MenuList = () => {
  const [menus, setMenus] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [menusPerPage] = useState(10)
  const [sortColumn, setSortColumn] = useState('name')
  const [sortOrder, setSortOrder] = useState('asc')

  useEffect(() => {
    fetch(Constants.getAllMenu)
      .then((response) => response.json())
      .then((data) => setMenus(data.menus))
      .catch((error) => console.error('Error fetching menus:', error))
  }, [])

  const indexOfLastMenu = currentPage * menusPerPage
  const indexOfFirstMenu = indexOfLastMenu - menusPerPage
  const currentMenus = menus.slice(indexOfFirstMenu, indexOfLastMenu)

  const paginate = (pageNumber) => setCurrentPage(pageNumber)

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value)
    setCurrentPage(1)
  }

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
    } else {
      setSortColumn(column)
      setSortOrder('asc')
    }
  }

  const sortedMenus = [...menus].sort((a, b) => {
    const compareValueA = a[sortColumn]
    const compareValueB = b[sortColumn]

    if (sortOrder === 'asc') {
      return compareValueA.localeCompare(compareValueB)
    } else {
      return compareValueB.localeCompare(compareValueA)
    }
  })

  const filteredMenus = sortedMenus.filter((menu) =>
    menu.name.toLowerCase().includes(searchQuery.toLowerCase()),
  )
  const handleDelete = (id) => {
    fetch(Constants.deleteMenuByID + id, {
      method: 'DELETE',
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to delete menu item')
        }

        return response.text()
      })
      .then((data) => {
        const message = data

        setMenus(menus.filter((menu) => menu._id !== id))
        Swal.fire('Success', message, 'success')
      })
      .catch((error) => {
        console.error('Error deleting menu item:', error)
        Swal.fire('Error', 'Failed to delete menu item', 'error')
      })
  }
  return (
    <div className="main-content">
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 text-info">MENU LIST </h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Home</Link>
                    </li>
                    <li className="breadcrumb-item active text-info">Menu List</li>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="m-2">
            <Col xs={12}>
              <div className="card custom-shadow rounded-lg border">
                <div className="card-body">
                  <Row className="d-flex  justify-content-between">
                    <Col md={4} xs={12} className="mb-3 text-center">
                      <h3 className="fw-bolder text-info mb-0">{menus.length}</h3>
                      <p className="text-secondary">Total Menu Items</p>
                    </Col>
                    <Col md={4} xs={12} className="mb-3 text-center">
                      <InputGroup className="mb-3">
                        <FormControl
                          placeholder="Search Menu..."
                          value={searchQuery}
                          onChange={handleSearchChange}
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                  <div>
                    <Button className="mb-3" variant="info" as={Link} to="/addNewMenu">
                      Add New Menu Item
                    </Button>
                    <Table responsive bordered hover>
                      <thead>
                        <tr>
                          <th>Menu ID</th>
                          <th onClick={() => handleSort('name')}>
                            Name
                            {sortColumn === 'name' && (
                              <span>{sortOrder === 'asc' ? ' 🔽' : ' 🔼'}</span>
                            )}
                          </th>
                          <th onClick={() => handleSort('price')}>
                            Price
                            {sortColumn === 'price' && (
                              <span>{sortOrder === 'asc' ? ' 🔽' : ' 🔼'}</span>
                            )}
                          </th>
                          <th>Sub Category</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentMenus.map((menu) => (
                          <tr key={menu._id}>
                            <td>{menu._id}</td>
                            <td>{menu.name}</td>
                            <td>{menu.price}</td>
                            <td>{menu.subcategory.name}</td>
                            <td>
                              <Link
                                to={`/editMenu/${menu._id}`}
                                className="btn btn-info btn-sm mx-2"
                              >
                                <FaRegEdit />
                              </Link>
                              <Button
                                variant="danger"
                                size="sm"
                                onClick={() => {
                                  MySwal.fire({
                                    title: 'Are you sure?',
                                    text: 'You will not be able to recover this menu item!',
                                    icon: 'warning',
                                    showCancelButton: true,
                                    confirmButtonColor: '#d33',
                                    cancelButtonColor: '#3085d6',
                                    confirmButtonText: 'Yes, delete it!',
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      handleDelete(menu._id)
                                    }
                                  })
                                }}
                              >
                                <FaTrashAlt />
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <ul className="pagination">
                      {Array.from({ length: Math.ceil(filteredMenus.length / menusPerPage) }).map(
                        (_, index) => (
                          <li key={index} className="page-item">
                            <button onClick={() => paginate(index + 1)} className="page-link">
                              {index + 1}
                            </button>
                          </li>
                        ),
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default MenuList
