import axios from 'axios'
import { useEffect, useState } from 'react'
import { Card, CardBody, Container, Spinner } from 'react-bootstrap'
import { useSearchParams } from 'react-router-dom'
import { Constants } from 'src/Constants'

const PurchaseSubscription = () => {
  const [errors, setErrors] = useState('')
  const [params] = useSearchParams()
  useEffect(() => {
    const email = params.get('email')
    const planId = params.get('planId')
    const userId = params.get('userId')
    const name = params.get('name')
    if (email && planId && userId && name) {
      axios
        .post(Constants.purchaseSubscription, {
          email,
          planId,
          userId,
          name,
          successUrl: 'https://admin.johnnytsbistroandblues.com/success-subscription',
        })
        .then((data) => {
          window.location.href = data.data.url
        })
        .catch((err) => {
          setErrors(err.response.data.message ?? 'An error occurred!')
        })
    }
  }, [params])

  return (
    <Container fluid>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <Card>
          <CardBody
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '1rem',
            }}
          >
            {!errors ? (
              <>
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
                <h5>Processing</h5>
              </>
            ) : (
              <h4
                style={{
                  color: 'red',
                }}
              >
                {errors}
              </h4>
            )}
          </CardBody>
        </Card>
      </div>
    </Container>
  )
}

export default PurchaseSubscription
