import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import axios from 'axios';

const HomePageDetails = () => {
  const [formData, setFormData] = useState({
    cardHeader: '',
    cardDetails: '',
    mainHeader: '',
    mainDetails: '',
    aboutDetails: '',
    aboutCardHeader: '',
    aboutCardDetails: '',
    roomDetails: '',
  });
  const [images, setImages] = useState({
    cardImage: null,
    mainImage: null,
    signatureImage: null,
    aboutCardImage: null,
  });

  useEffect(() => {
    fetchHomePageDetails();
  }, []);

  const fetchHomePageDetails = async () => {
    try {
      const response = await axios.get('https://api.johnnytsbistroandblues.com/api/home-page-details/getAllDetails');
      if (response.data.status === 1) {
        setFormData(response.data.homePageDetails);
      }
    } catch (error) {
      console.error('Error fetching home page details:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleImageChange = (e) => {
    const { name, files } = e.target;
    setImages((prevImages) => ({ ...prevImages, [name]: files[0] }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    for (const key in formData) {
      data.append(key, formData[key]);
    }
    for (const key in images) {
      if (images[key]) {
        data.append(key, images[key]);
      }
    }

    try {
      const response = await axios.post('https://api.johnnytsbistroandblues.com/api/home-page-details/upload-or-update', data);
      if (response.data.status === 1) {
        Swal.fire('Success', response.data.message, 'success');
        fetchHomePageDetails();
      } else {
        Swal.fire('Error', response.data.error, 'error');
      }
    } catch (error) {
      console.error('Error uploading/updating home page details:', error);
      Swal.fire('Error', 'An error occurred while uploading/updating home page details.', 'error');
    }
  };

  return (
    <div className="main-content">
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 text-info">HOME PAGE DETAILS</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Home</Link>
                    </li>
                    <li className="breadcrumb-item text-info active">Home Page Details</li>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>
          <Form onSubmit={handleSubmit}>
            {/* Main Section */}
            <Row className="mt-4">
              <Col>
                <h5 className="text-info">Main Body Section</h5>
                <Form.Group>
                  <Form.Label>Card Header</Form.Label>
                  <Form.Control
                    type="text"
                    name="cardHeader"
                    value={formData.cardHeader}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Card Details</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="cardDetails"
                    value={formData.cardDetails}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Main Header</Form.Label>
                  <Form.Control
                    type="text"
                    name="mainHeader"
                    value={formData.mainHeader}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Main Details</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="mainDetails"
                    value={formData.mainDetails}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Card Image</Form.Label>
                  <Form.Control
                    type="file"
                    name="cardImage"
                    onChange={handleImageChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Main Image</Form.Label>
                  <Form.Control
                    type="file"
                    name="mainImage"
                    onChange={handleImageChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Signature Image</Form.Label>
                  <Form.Control
                    type="file"
                    name="signatureImage"
                    onChange={handleImageChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            {/* About Our Menu Section */}
            <Row className="mt-4">
              <Col>
                <h5 className="text-info">About Our Menu Section</h5>
                <Form.Group>
                  <Form.Label>About Details</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="aboutDetails"
                    value={formData.aboutDetails}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>About Card Header</Form.Label>
                  <Form.Control
                    type="text"
                    name="aboutCardHeader"
                    value={formData.aboutCardHeader}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>About Card Details</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="aboutCardDetails"
                    value={formData.aboutCardDetails}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>About Card Image</Form.Label>
                  <Form.Control
                    type="file"
                    name="aboutCardImage"
                    onChange={handleImageChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            {/* Room Section */}
            <Row className="mt-4">
              <Col>
                <h5 className="text-info">Room Section</h5>
                <Form.Group>
                  <Form.Label>Room Details</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="roomDetails"
                    value={formData.roomDetails}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Button variant="primary" type="submit" className="mt-4">
              Submit
            </Button>
          </Form>
        </Container>
      </div>
    </div>
  );
};

export default HomePageDetails;
