import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Container, Row, Col, Table, Button, Card, Image, Form, Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { Constants, imageUrl } from 'src/Constants'
const MySwal = withReactContent(Swal)

const Media = () => {
  const [media, setMedia] = useState([])
  const [file, setFile] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    fetchMedia()
  }, [])

  const fetchMedia = async () => {
    try {
      const response = await fetch(Constants.getAllMediaSectionImages)
      if (!response.ok) {
        throw new Error('Failed to fetch media')
      }
      const data = await response.json()
      setMedia(data.media)
    } catch (error) {
      console.error('Error fetching media:', error)
    } finally {
      setLoading(false)
    }
  }

  const handleFileChange = (e) => {
    setFile(e.target.files[0])
  }

  const handleUpload = async () => {
    try {
      const formData = new FormData()
      formData.append('image', file)

      const response = await fetch(Constants.uploadMediaSectionImage, {
        method: 'POST',
        body: formData,
      })

      if (response.ok) {
        await fetchMedia()
        MySwal.fire({
          icon: 'success',
          title: 'Media Uploaded Successfully',
        })
      } else {
        throw new Error('Failed to upload media')
      }
    } catch (error) {
      console.error('Error uploading media:', error)
      MySwal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Failed to upload media!',
      })
    }
  }

  const handleDelete = async (mediaId) => {
    try {
      const response = await fetch(Constants.deleteMediaSectionImageByID + mediaId, {
        method: 'DELETE',
      })

      if (response.ok) {
        await fetchMedia()
        MySwal.fire({
          icon: 'success',
          title: 'Media Deleted Successfully',
        })
      } else {
        throw new Error('Failed to delete media')
      }
    } catch (error) {
      console.error('Error deleting media:', error)
      MySwal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Failed to delete media!',
      })
    }
  }

  return (
    <div className="main-content">
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 text-info">MEDIA UPLOADS</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Home</Link>
                    </li>
                    <li className="breadcrumb-item text-info active">Media</li>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="card custom-shadow rounded-lg border my-3">
                <div className="card-body">
                  <Row>
                    <Col md={4} xs={12} className="mb-3 text-center">
                      <h3 className="fw-bolder text-info mb-0">{media.length}</h3>
                      <p className="text-secondary">Total Media</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={8} xs={12} className="mb-3">
                      <div style={{ width: '50%' }}>
                        <Form>
                          <Form.Group controlId="formFile" className="mb-3">
                            <Form.Label>Upload Image</Form.Label>
                            <Form.Control type="file" onChange={handleFileChange} />
                          </Form.Group>
                          <Button variant="info" className="ms-2" onClick={handleUpload}>
                            Upload
                          </Button>
                        </Form>
                      </div>
                    </Col>
                  </Row>

                  {loading ? (
                    <div className="d-flex justify-content-center align-items-center vh-50">
                      <Spinner animation="border" role="status">
                        <span className="sr-only"></span>
                      </Spinner>
                    </div>
                  ) : (
                    <Row>
                      {media.map((item) => (
                        <Col key={item._id} xs={6} md={4} lg={3}>
                          <Card className="mb-3">
                            <Card.Img
                              variant="top"
                              src={`${imageUrl}${item.image}`}
                            />
                            <Button
                              variant="danger"
                              className="position-absolute top-0 end-0 m-2"
                              onClick={() => handleDelete(item._id)}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </Button>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default Media
