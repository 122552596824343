import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button, Modal, Form, Table } from 'react-bootstrap'
import { FaRegEdit, FaTrashAlt } from 'react-icons/fa'
import Swal from 'sweetalert2'
import { Constants } from 'src/Constants'

const CategoryList = () => {
  const [showAddModal, setShowAddModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [categories, setCategories] = useState([])
  const [editCategory, setEditCategory] = useState({
    _id: '',
    name: '',
  })

  const handleAddClose = () => setShowAddModal(false)
  const handleAddShow = () => setShowAddModal(true)

  const handleEditClose = () => setShowEditModal(false)
  const handleEditShow = (category) => {
    setEditCategory(category)
    setShowEditModal(true)
  }

  // Function to fetch all categories
  const fetchCategories = async () => {
    try {
      const response = await fetch(Constants.getAllCategory)
      const data = await response.json()
      if (data.status === 1) {
        setCategories(data.categories)
      } else {
        throw new Error(data.message || 'Failed to fetch categories')
      }
    } catch (error) {
      console.error('Error fetching categories:', error)
    }
  }

  useEffect(() => {
    fetchCategories()
  }, [])

  const handleAddCategory = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const name = formData.get('name')

    try {
      const response = await fetch(Constants.addCategory, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name }),
      })
      const data = await response.json()
      if (data.status === 1) {
        fetchCategories()

        handleAddClose()

        Swal.fire('Success', 'Category added successfully!', 'success')
      } else {
        throw new Error(data.message || 'Failed to add category')
      }
    } catch (error) {
      console.error('Error adding category:', error)
      Swal.fire('Error', 'Failed to add category', 'error')
    }
  }
  const handleDeleteCategory = async (categoryId) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this category!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await fetch(Constants.deleteCategoryById + categoryId, {
            method: 'DELETE',
          })

          if (response.ok) {
            fetchCategories() / Swal.fire('Deleted!', 'Your category has been deleted.', 'success')
          } else {
            throw new Error('Failed to delete category')
          }
        } catch (error) {
          console.error('Error deleting category:', error)
          Swal.fire('Error', 'Failed to delete category', 'error')
        }
      }
    })
  }
  const handleEditCategory = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const name = formData.get('name')

    try {
      const response = await fetch(Constants.editCategoryById + editCategory._id, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name }),
      })

      const data = await response.json()

      if (response.ok) {
        fetchCategories()

        handleEditClose()

        Swal.fire('Success', 'Category updated successfully!', 'success')
      } else {
        throw new Error(data.message || 'Failed to update category')
      }
    } catch (error) {
      console.error('Error updating category:', error)
      Swal.fire('Error', 'Failed to update category', 'error')
    }
  }

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="mb-0 text-info">CATEGORY LIST</h4>
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li className="breadcrumb-item active text-info">Category List </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="row m-2">
          <div className="col-xl-12">
            <div className="card custom-shadow rounded-lg border">
              <div className="card-body">
                <div className="">
                  <div className="dataTables_length">
                    <div></div>
                    <div className="search-box">
                      <Button variant="primary" className="my-2" onClick={handleAddShow}>
                        Add New Category
                      </Button>
                      <Modal show={showAddModal} onHide={handleAddClose}>
                        <Modal.Header closeButton>
                          <Modal.Title>Add New Category</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <Form onSubmit={handleAddCategory}>
                            <Form.Group controlId="categoryName">
                              <Form.Label>Category Name</Form.Label>
                              <Form.Control
                                type="text"
                                name="name"
                                placeholder="Enter category name"
                                required
                              />
                            </Form.Group>
                            <Button
                              variant="secondary"
                              onClick={handleAddClose}
                              style={{ marginRight: '10px', marginTop: '10px' }}
                            >
                              Close
                            </Button>
                            <Button variant="primary" type="submit" style={{ marginTop: '10px' }}>
                              Save Category
                            </Button>
                          </Form>
                        </Modal.Body>
                      </Modal>
                    </div>
                  </div>

                  <Table responsive bordered hover>
                    <thead>
                      <tr>
                        <th>Category ID</th>
                        <th>Category Name</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {categories.map((category) => (
                        <tr key={category._id}>
                          <td>{category._id}</td>
                          <td>{category.name}</td>
                          <td>
                            <Button
                              variant="primary"
                              size="sm"
                              style={{ marginRight: '10px' }}
                              onClick={() => handleEditShow(category)}
                            >
                              <FaRegEdit />
                            </Button>
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteCategory(category._id)}
                            >
                              <FaTrashAlt />
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <Modal show={showEditModal} onHide={handleEditClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>Edit Category</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Form onSubmit={handleEditCategory}>
                        <Form.Group controlId="editCategoryName">
                          <Form.Label>Category Name</Form.Label>
                          <Form.Control
                            type="text"
                            name="name"
                            placeholder="Enter category name"
                            defaultValue={editCategory.name}
                            required
                          />
                        </Form.Group>
                        <Button
                          variant="secondary"
                          onClick={handleEditClose}
                          style={{ marginRight: '10px', marginTop: '10px' }}
                        >
                          Close
                        </Button>
                        <Button variant="primary" type="submit" style={{ marginTop: '10px' }}>
                          Save Changes
                        </Button>
                      </Form>
                    </Modal.Body>
                  </Modal>
                  <div className="container"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CategoryList
