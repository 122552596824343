import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Constants } from 'src/Constants'

const MySwal = withReactContent(Swal)

const AddNewEvent = () => {
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    heading: '',
    subheading: '',
    description: '',
    image: null,
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleImageChange = (e) => {
    setFormData({
      ...formData,
      image: e.target.files[0],
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      const formDataToSend = new FormData()
      formDataToSend.append('heading', formData.heading)
      formDataToSend.append('subheading', formData.subheading)
      formDataToSend.append('description', formData.description)
      formDataToSend.append('image', formData.image)

      const response = await fetch(Constants.createEvent, {
        method: 'POST',
        body: formDataToSend,
      })

      if (response.ok) {
        MySwal.fire({
          icon: 'success',
          title: 'Event Added Successfully',
        })
        navigate('/eventList')
      } else {
        throw new Error('Failed to add event')
      }
    } catch (error) {
      console.error('Error adding event:', error)
      MySwal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Failed to add event!',
      })
    }
  }

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0 text-info">ADD NEW EVENT</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item active text-info">Add New Event</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="card custom-shadow rounded-lg border my-2">
                  <div className="card-body">
                    <Form onSubmit={handleSubmit}>
                      <div className="row">
                        <Form.Group className="col-lg-6 mb-3" controlId="heading">
                          <Form.Label>Event Heading</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Name"
                            name="heading"
                            value={formData.heading}
                            onChange={handleChange}
                            required
                          />
                        </Form.Group>

                        <Form.Group className="col-lg-6 mb-3" controlId="subheading">
                          <Form.Label>Event Sub Heading</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Sub Heading"
                            name="subheading"
                            value={formData.subheading}
                            onChange={handleChange}
                          />
                        </Form.Group>

                        <Form.Group className="col-lg-6 mb-3" controlId="description">
                          <Form.Label>Event Description</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={4}
                            placeholder="Enter Event Description"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                          />
                        </Form.Group>

                        <Form.Group className="col-lg-6 mb-3" controlId="image">
                          <Form.Label>Event Image</Form.Label>
                          <Form.Control type="file" name="image" onChange={handleImageChange} />
                        </Form.Group>

                        <div className="text-end">
                          <Button
                            variant="outline-info"
                            className="min-btn m-2"
                            as={Link}
                            to="/dashboard"
                          >
                            Cancel
                          </Button>
                          <Button variant="info" className="min-btn m-2" type="submit">
                            Save
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddNewEvent
