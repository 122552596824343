import React from 'react'

import Login from './views/login/Login.js'
import Dashboard from './views/dashboard/Dashboard'

import Settings from './views/settings/Settings.js'

import UserList from './views/userList/UserList'
import UserDetails from './views/userDetails/UserDetails'
import AddNewUser from './views/addNewUser/AddNewUser'

import AboutUsForm from './views/aboutUs/AboutUs'
import PolicyAndServices from './views/policyAndService/PolicyAndServices'
import ContactMessages from './views/contactMessages/ContactMessages'

import EventList from './views/eventList/EventList.js'
import AddNewEvent from './views/addNewEvent/AddNewEvent.js'
import EditEvent from './views/editEvent/EditEvent.js'
import Media from './views/media/Media.js'
import Enquiry from './views/enquiry/Enquiry.js'
import CategoryList from './views/categoryList/CategoryList.js'
import SubCategoryList from './views/subCategoryList/SubCategoryList.js'
import MenuBanner from './views/menuBanner/MenuBanner.js'
import MenuList from './views/menuList/MenuList.js'
import AddNewMenu from './views/addNewMenu/AddNewMenu.js'
import EditMenu from './views/editMenu/EditMenu.js'
import BannerList from './views/bannerList/BannerList.js'
import AddNewBanner from './views/addNewBanner/AddNewBanner.js'
import EditBanner from './views/editBanner/EditBanner.js'
import SubscriptionPackages from './views/subscription/SubscriptionPackages.js'
import PurchaseSubscription from './views/PurchaseSubscription/PurchaseSubscription.jsx'
import HomePageDetails from './views/homePageDetails/HomePageDetails.js'
import Subscribers from './views/subscribers/Subscribers.js'

const routes = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/login', name: 'Login', element: Login },
  { path: '/dashboard', name: 'Dashboard', element: Dashboard },

  { path: '/settings', name: 'Settings', element: Settings },

  { path: '/userList', name: 'userList', element: UserList },
  { path: '/userDetails/:id', name: 'userDetails', element: UserDetails },
  { path: '/addNewUser', name: 'addNewUser', element: AddNewUser },

  { path: '/aboutUs', name: 'aboutUs', element: AboutUsForm },
  { path: '/policy', name: 'policy', element: PolicyAndServices },
  { path: '/contact-messages', name: 'contact-messages', element: ContactMessages },
  { path: '/enquiry-messages', name: 'enquiry-messages', element: Enquiry },
  { path: '/eventList', name: 'eventList', element: EventList },
  { path: '/addNewEvent', name: 'addNewEvent', element: AddNewEvent },
  { path: '/edit-event/:eventId', name: 'editEvent', element: EditEvent },
  { path: '/media', name: 'media', element: Media },

  { path: '/categoryList', name: 'categoryList', element: CategoryList },
  { path: '/subCategoryList', name: 'subCategoryList', element: SubCategoryList },
  { path: '/menuBanner', name: 'menuBanner', element: MenuBanner },
  { path: '/menuList', name: 'menuList', element: MenuList },
  { path: '/addNewMenu', name: 'addNewMenu', element: AddNewMenu },
  { path: '/editMenu/:menuId', name: 'editMenu', element: EditMenu },
  { path: '/bannerList', name: 'bannerList', element: BannerList },
  { path: '/addNewBanner', name: 'addNewBanner', element: AddNewBanner },
  { path: '/editBanner/:bannerID', name: 'editBanner', element: EditBanner },
  { path: '/subscription', name: 'subscription', element: SubscriptionPackages },
  { path: '/Subscribers-Details', name: 'subscription', element: Subscribers },
  { path: '/homePageDetails', name: 'homePageDetails', element: HomePageDetails },
]

export default routes
