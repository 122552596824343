import React, { useState, useEffect } from 'react'
import { FaEye } from 'react-icons/fa'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Container, Row, Col, Table, Button } from 'react-bootstrap'
import { Constants } from 'src/Constants'
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai'
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai'
const MySwal = withReactContent(Swal)

const EventList = () => {
  const [events, setEvents] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const location = useLocation()

  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [sortColumn, setSortColumn] = useState('')
  const [sortOrder, setSortOrder] = useState('asc')

  useEffect(() => {
    const fetEvents = async () => {
      try {
        const response = await fetch(Constants.getAllEvents)
        if (!response.ok) {
          throw new Error('Failed to fetch events')
        }
        const data = await response.json()
        setEvents(data.events)
      } catch (error) {
        console.error('Error fetching events:', error.message)
      }
    }

    fetEvents()
  }, [])

  const deleteUserById = async (userId) => {
    try {
      const confirmResult = await MySwal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this event!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel!',
        // reverseButtons: true,
      })

      if (confirmResult.isConfirmed) {
        const response = await fetch(Constants.deleteEventById + userId, {
          method: 'DELETE',
        })

        if (!response.ok) {
          throw new Error('Failed to delete event')
        }

        setEvents((prevCustomers) => prevCustomers.filter((customer) => customer._id !== userId))

        MySwal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Event deleted successfully',
        })
      }
    } catch (error) {
      console.error('Error deleting event:', error.message)

      MySwal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to delete user',
      })
    }
  }

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'))
    } else {
      setSortColumn(column)
      setSortOrder('asc')
    }
  }
  // Filter customers based on the search term
  const filteredCustomers = events.filter((customer) =>
    Object.values(customer).some(
      (value) =>
        typeof value === 'string' && value.toLowerCase().includes(searchTerm.toLowerCase()),
    ),
  )

  const sortedCustomers = filteredCustomers.sort((a, b) => {
    const columnA = a[sortColumn]
    const columnB = b[sortColumn]

    if (columnA && columnB) {
      const valueA = typeof columnA === 'string' ? columnA : String(columnA)
      const valueB = typeof columnB === 'string' ? columnB : String(columnB)

      if (sortOrder === 'asc') {
        return valueA.localeCompare(valueB)
      } else {
        return valueB.localeCompare(valueA)
      }
    }

    return 0
  })

  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = filteredCustomers.slice(indexOfFirstItem, indexOfLastItem)

  const paginate = (pageNumber) => setCurrentPage(pageNumber)

  return (
    <div className="main-content">
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 text-info">EVENT LIST</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Home</Link>
                    </li>
                    <li className="breadcrumb-item text-info active">Event List</li>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="card custom-shadow rounded-lg border my-3">
                <div className="card-body">
                  <Row>
                    <Col md={4} xs={12} className="mb-3 text-center">
                      <h3 className="fw-bolder text-info mb-0">{events.length}</h3>
                      <p className="text-secondary">Total Events</p>
                    </Col>
                  </Row>

                  <div className="dataTables_length mb-2">
                    <div className="search-box">
                      <input
                        type="text"
                        id="search"
                        placeholder="Search "
                        name="search"
                        className="form-control "
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="table-responsive">
                    <Table responsive bordered hover>
                      <thead>
                        <tr>
                          <th onClick={() => handleSort('heading')}>
                            Event Name
                            {sortColumn === 'heading' && (
                              <span>{sortOrder === 'asc' ? ' 🔽' : ' 🔼'}</span>
                            )}
                          </th>

                          <th onClick={() => handleSort('subheading')}>
                            Sub Heading
                            {sortColumn === 'subheading' && (
                              <span>{sortOrder === 'asc' ? ' 🔽' : ' 🔼'}</span>
                            )}
                          </th>

                          <th>Actions</th>
                        </tr>
                      </thead>

                      <tbody>
                        {currentItems?.map((customer, index) => (
                          <tr key={customer?._id}>
                            <td>{customer?.heading}</td>

                            <td>{customer?.subheading}</td>

                            <td>
                              <div>
                                <Link to={`/edit-event/${customer._id}`}>
                                  <AiOutlineEdit
                                    className="text-info me-1"
                                    title="Edit"
                                    style={{ fontSize: '1.5rem' }}
                                  />
                                </Link>

                                <AiOutlineDelete
                                  className="text-danger"
                                  title="Delete"
                                  style={{ fontSize: '1.5rem' }}
                                  onClick={() => deleteUserById(customer._id)}
                                />
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                  <Container>
                    <div className="col-sm-12 col-md-5">
                      <div className="dataTables_length">
                        Showing {indexOfFirstItem + 1} to {Math.min(indexOfLastItem, events.length)}{' '}
                        of {events.length} entries
                      </div>
                    </div>
                    <div>
                      {Array.from({
                        length: Math.ceil(events.length / itemsPerPage),
                      }).map((_, index) => (
                        <button
                          key={index}
                          type="button"
                          className={`btn ${
                            currentPage === index + 1
                              ? 'btn-info rounded-circle text-white m-1'
                              : 'btn-outline-info m-1 btn-sm'
                          }`}
                          onClick={() => paginate(index + 1)}
                        >
                          {index + 1}
                        </button>
                      ))}
                    </div>
                  </Container>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default EventList
