import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Table, Button, Pagination } from 'react-bootstrap'
import axios from 'axios'
import Swal from 'sweetalert2'
import { FaTrashAlt } from 'react-icons/fa'

const Subscribers = () => {
  const [subscriptions, setSubscriptions] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 20

  useEffect(() => {
    const fetchSubscriptions = async () => {
      try {
        const response = await axios.get(
          'https://api.johnnytsbistroandblues.com/api/subscription/all',
        )
        if (response.data.status === 1) {
          setSubscriptions(response.data.subscriptions)
        }
      } catch (error) {
        console.error('Error fetching subscriptions:', error)
      }
    }

    fetchSubscriptions()
  }, [])

  const getPlanName = (planId) => {
    switch (planId) {
      case 'plan_Q07F04LyvQmTFa':
        return 'INDIVIDUAL'
      case 'plan_Q07HJURWT25xnd':
        return 'CORPORATE'
      case 'plan_Q07HGh6UMogIuo':
        return 'INDIVIDUAL WITH HOUSE LOCKER'
      default:
        return planId
    }
  }

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(
        `https://api.johnnytsbistroandblues.com/api/subscription/delete/${id}`,
      )
      if (response.status === 200) {
        Swal.fire({
          title: 'Deleted!',
          text: 'The subscription has been deleted.',
          icon: 'success',
          confirmButtonText: 'OK',
        })
        setSubscriptions(subscriptions.filter((subscription) => subscription._id !== id))
      }
    } catch (error) {
      console.error('Error deleting subscription:', error)
      Swal.fire({
        title: 'Error!',
        text: 'There was an error deleting the subscription.',
        icon: 'error',
        confirmButtonText: 'OK',
      })
    }
  }

  const paginate = (pageNumber) => setCurrentPage(pageNumber)

  const currentSubscriptions = subscriptions.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage,
  )

  const styles = {
    mainContent: {
      padding: '20px',
    },
    pageTitleBox: {
      background: '#f8f9fa',
      padding: '15px',
      borderRadius: '5px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    },
    table: {
      marginTop: '20px',
    },
    tableHeader: {
      backgroundColor: '#f1f1f1',
    },
    customShadow: {
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    deleteButton: {
      backgroundColor: 'red',
      borderColor: 'red',
      color: 'white',
    },
  }

  return (
    <div style={styles.mainContent}>
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div
                style={styles.pageTitleBox}
                className="page-title-box d-flex align-items-center justify-content-between"
              >
                <h4 className="mb-0 text-info">SUBSCRIBERS DETAILS</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Home</Link>
                    </li>
                    <li className="breadcrumb-item active text-info">Subscribers Details</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div className="row m-2">
            <div className="col-xl-12">
              <div className="card rounded-lg border" style={styles.customShadow}>
                <div className="card-body">
                  <Table striped bordered hover responsive style={styles.table}>
                    <thead>
                      <tr style={styles.tableHeader}>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Created Date</th>
                        <th>Expiry Date</th>
                        <th>Plan</th>
                        <th>Payment Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentSubscriptions.map((subscription) => (
                        <tr key={subscription?._id}>
                          <td>{subscription?.userId?.name}</td>
                          <td>{subscription?.userId?.email}</td>
                          <td>{new Date(subscription?.createdDate).toLocaleDateString()}</td>
                          <td>{new Date(subscription?.expiryDate).toLocaleDateString()}</td>
                          <td>{getPlanName(subscription?.planId)}</td>
                          <td>{subscription?.status.toUpperCase()}</td>
                          <td>
                            <Button
                              style={styles.deleteButton}
                              onClick={() => handleDelete(subscription?._id)}
                            >
                              <FaTrashAlt size={14} />
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <Pagination>
                    {[...Array(Math.ceil(subscriptions.length / itemsPerPage)).keys()].map(
                      (number) => (
                        <Pagination.Item
                          key={number + 1}
                          active={number + 1 === currentPage}
                          onClick={() => paginate(number + 1)}
                        >
                          {number + 1}
                        </Pagination.Item>
                      ),
                    )}
                  </Pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Subscribers
