// const prefix = 'https://johnnytsbistroandbluesapi.goigi.me/api/'
const prefix = 'https://api.johnnytsbistroandblues.com/api/'
// // const prefix = 'http://localhost:6060/api/'
// // const isLocalhost = window.location.hostname === 'localhost';
// // const prefix = isLocalhost ? 'http://localhost:6060/api/' : 'https://icarbuyersapi.goigi.me/api/';

export const Constants = {
  adminLogIn: prefix + 'admin/login',
  getAdminDetails: prefix + 'admin',
  registerOrUpdateAdmin: prefix + 'admin/register',

  getAllUsers: prefix + 'auth/users',
  addNewUser: prefix + 'auth/register',
  deleteUserById: prefix + 'auth/delete-user/',

  //Category List
  getAllCategory: prefix + 'category/all-categories',
  addCategory: prefix + 'category/create',
  deleteCategoryById: prefix + 'category/delete-category/',
  editCategoryById: prefix + 'category/update-category/',

  //Sub Category
  createSubCategory: prefix + 'subcategory/create',
  getAllSubCategory: prefix + 'subcategory/all-subcategories',
  deleteSubCategoryByID: prefix + 'subcategory/delete-subcategory/',
  editSubCategoryByID: prefix + 'subcategory/update-subcategory/',
  getSubCategoryByID: prefix + 'subcategory/subcategory/',
  getSubcategoriesByCategoryName: prefix + 'category/subcategory-list/',

  //menu list
  getAllMenu: prefix + 'menu/all-menus',
  createMenu: prefix + 'menu/create',
  deleteMenuByID: prefix + 'menu/delete-menu/',
  getMenuByID: prefix + 'menu/menu/',
  editMenuByID: prefix + 'menu/update-menu/',

  getAllEvents: prefix + 'events/all-events',
  deleteEventById: prefix + 'events/delete-event/',
  createEvent: prefix + 'events/create',
  editEventById: prefix + 'events/update-event/',
  getEventDetailsByID: prefix + 'events/event/',

  //Media Section
  getAllMediaSectionImages: prefix + 'media/all-media',
  uploadMediaSectionImage: prefix + 'media/create',
  deleteMediaSectionImageByID: prefix + 'media/delete-media/',

  getMenuBanner: prefix + 'menuBanner/menu-banner',
  uploadOrUpdateMenuBanner: prefix + 'menuBanner/upload-or-update',

  getAllBannerList: prefix + 'banner/all-banners',
  deleteBannerListByID: prefix + 'banner/delete-banner/',
  createBannerList: prefix + 'banner/create',
  getBannerDetailsByID: prefix + 'banner/banner/',
  editBannerListByID: prefix + 'banner/update-banner/',

  getAllContactMessages: prefix + 'contact/contact-messages',
  deleteContactMessagesByID: prefix + 'contact/delete-message/',
  deleteAllContactMessages: prefix + 'contact/delete-all-messages',
  getAllEnquiryMessages: prefix + 'enquiry/enquiry-messages',
  deleteEnquiryMessageByID: prefix + 'enquiry/delete-message/',
  deleteAllEnquiryMessages: prefix + 'enquiry/delete-all-messages',

  getRewardsById: prefix + 'rewardsPoint/get/',
  uploadOrUpdateRewardsById: prefix + 'rewardsPoint/upload-or-update/',
  purchaseSubscription: prefix + 'subscription',
}

export const imageUrl = 'https://api.johnnytsbistroandblues.com/static/'
