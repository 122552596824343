import React from 'react'
import CIcon from '@coreui/icons-react'

// import {BiUserPlus } from 'react-icons/bi'
import {
  cilAddressBook,
  cilMoney,
  cilSettings,
  cilPlaylistAdd,
  cilListRich,
  cilAirplay,
  cilCarAlt,
  cilInstitution,
  cilUser,
  cilUserFollow,
  cibHackhands,
  cilList,
  cilChatBubble,
  cilPizza,
  cilDinner,
  cilDrinkAlcohol,
  cilImage,
  cilPhone,
  cilLibrary,
  cilImagePlus,
  cilGroup,
} from '@coreui/icons'
import { CNavGroup, CNavItem } from '@coreui/react'

const _nav = [
  {
    component: CNavItem,
    name: 'MENU',
    to: '/dashboard',
    // icon: <CIcon icon={cilLayers} customClassName="nav-icon" />,
    disabled: true,
  },

  {
    component: CNavItem,
    name: 'Dashboard',
    to: '/dashboard',
    icon: <CIcon icon={cilAirplay} customClassName="nav-icon" />,
  },
  {
    component: CNavGroup,
    name: 'User Management',
    icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'User List',
        icon: <CIcon icon={cilAddressBook} customClassName="nav-icon" />,
        to: '/userList',
      },
      {
        component: CNavItem,
        icon: <CIcon icon={cilUserFollow} customClassName="nav-icon" />,
        name: 'Add New User',
        to: '/addNewUser',
      },
    ],
  },

  {
    component: CNavGroup,
    name: 'Menu Management',
    icon: <CIcon icon={cilDinner} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Category List',
        icon: <CIcon icon={cilListRich} customClassName="nav-icon" />,
        to: '/categoryList',
      },
      {
        component: CNavItem,
        name: 'Sub Category  List',
        icon: <CIcon icon={cilListRich} customClassName="nav-icon" />,
        to: '/subCategoryList',
      },

      {
        component: CNavItem,
        name: 'Menu List',
        icon: <CIcon icon={cilPizza} customClassName="nav-icon" />,
        to: '/menuList',
      },
    ],
  },
  {
    component: CNavGroup,
    name: 'Event Management',
    icon: <CIcon icon={cilDrinkAlcohol} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Event List',
        icon: <CIcon icon={cilAddressBook} customClassName="nav-icon" />,
        to: '/eventList',
      },
      {
        component: CNavItem,
        icon: <CIcon icon={cilPlaylistAdd} customClassName="nav-icon" />,
        name: 'Add New Event',
        to: '/addNewEvent',
      },
    ],
  },
  // {
  //   component: CNavItem,
  //   name: 'Financial Management',
  //   to: '/financeManagement',
  //   icon: <CIcon icon={cilMoney} customClassName="nav-icon" />,
  // },
  {
    component: CNavGroup,
    name: 'Content Management',
    icon: <CIcon icon={cibHackhands} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Home Page Details',
        icon: <CIcon icon={cilList} customClassName="nav-icon" />,
        to: '/homePageDetails',
      },
      {
        component: CNavItem,
        name: 'Banner List',
        icon: <CIcon icon={cilLibrary} customClassName="nav-icon" />,
        to: '/bannerList',
      },
      {
        component: CNavItem,
        name: 'Media',
        icon: <CIcon icon={cilImage} customClassName="nav-icon" />,
        to: '/media',
      },
      {
        component: CNavItem,
        name: 'Menu Banner',
        icon: <CIcon icon={cilImagePlus} customClassName="nav-icon" />,
        to: '/menuBanner',
      },
    ],
  },
  {
    component: CNavItem,
    name: 'Subscription Packages',
    to: '/subscription',
    icon: <CIcon icon={cilMoney} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Subscribers',
    to: '/Subscribers-Details',
    icon: <CIcon icon={cilGroup} customClassName="nav-icon" />,
  },
  
  {
    component: CNavItem,
    name: 'Contact-Messages',
    to: '/contact-messages',
    icon: <CIcon icon={cilChatBubble} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Enquiry-Messages',
    to: '/enquiry-messages',
    icon: <CIcon icon={cilPhone} customClassName="nav-icon" />,
  },

  {
    component: CNavItem,
    name: 'Settings',
    to: '/settings',
    icon: <CIcon icon={cilSettings} customClassName="nav-icon" />,
  },
]

export default _nav
