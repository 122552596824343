import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Form, Button, FormGroup, FormLabel, FormControl } from 'react-bootstrap'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Constants } from 'src/Constants'

const MySwal = withReactContent(Swal)

const AddNewMenu = () => {
  const navigate = useNavigate()
  const [categories, setCategories] = useState([])
  const [subcategories, setSubcategories] = useState([])
  const [formData, setFormData] = useState({
    category: '',
    subcategory: '',
    name: '',
    price: '',
    description: '',
    heading: '',
    image: null,
  })

  useEffect(() => {
    // Fetch categories
    fetch(Constants.getAllCategory)
      .then((response) => response.json())
      .then((data) => setCategories(data.categories))
      .catch((error) => console.error('Error fetching categories:', error))
  }, [])

  const handleInputChange = (event) => {
    const { name, value } = event.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleFileChange = (event) => {
    const { name, files } = event.target
    setFormData({
      ...formData,
      [name]: files[0],
    })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    const formDataToSend = new FormData()
    for (const key in formData) {
      formDataToSend.append(key, formData[key])
    }

    try {
      const response = await fetch(Constants.createMenu, {
        method: 'POST',
        body: formDataToSend,
      })
      const data = await response.json()
      if (data.status === 1) {
        MySwal.fire('Success!', 'Menu item added successfully!', 'success')
        navigate('/menuList')
      } else {
        MySwal.fire('Error!', 'Failed to add menu item', 'error')
      }
    } catch (error) {
      console.error('Error adding menu item:', error)
      MySwal.fire('Error!', 'Failed to add menu item', 'error')
    }
  }
  const handleCategoryChange = async (event) => {
    const selectedCategory = event.target.value
    // Fetch subcategories based on the selected category
    try {
      const response = await fetch(Constants.getSubcategoriesByCategoryName + selectedCategory)
      const data = await response.json()
      if (data.status === 1) {
        setSubcategories(data.subcategories)
        // Store the selected category name in the form data
        setFormData({
          ...formData,
          category: selectedCategory,
        })
      } else {
        setSubcategories([])
      }
    } catch (error) {
      console.error('Error fetching subcategories:', error)
      setSubcategories([])
    }
  }

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0 text-info">ADD NEW MENU</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item active text-info">Add New Menu</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="card custom-shadow rounded-lg border my-2">
                  <div className="card-body">
                    <Form onSubmit={handleSubmit}>
                      <FormGroup className="mb-2">
                        <FormLabel>Name</FormLabel>
                        <FormControl type="text" name="name" onChange={handleInputChange} />
                      </FormGroup>
                      <FormGroup className="mb-2">
                        <FormLabel>Select Category</FormLabel>
                        <FormControl as="select" name="category" onChange={handleCategoryChange}>
                          <option value="">Select Category</option>
                          {categories?.map((category) => (
                            <option key={category._id} value={category?.name}>
                              {category?.name}
                            </option>
                          ))}
                        </FormControl>
                      </FormGroup>
                      <FormGroup className="mb-2">
                        <FormLabel>Select Sub Category</FormLabel>
                        <FormControl as="select" name="subcategory" onChange={handleInputChange}>
                          <option value="">Select Sub Category</option>
                          {subcategories?.map((subcategory) => (
                            <option key={subcategory._id} value={subcategory?.name}>
                              {subcategory?.name}
                            </option>
                          ))}
                        </FormControl>
                      </FormGroup>

                      <FormGroup className="mb-2">
                        <FormLabel>Price</FormLabel>
                        <FormControl type="text" name="price" onChange={handleInputChange} />
                      </FormGroup>
                      <FormGroup className="mb-2">
                        <FormLabel>Description</FormLabel>
                        <FormControl
                          as="textarea"
                          rows={3}
                          name="description"
                          onChange={handleInputChange}
                        />
                      </FormGroup>
                      {/* <FormGroup className="mb-2">
                        <FormLabel>Heading</FormLabel>
                        <FormControl type="text" name="heading" onChange={handleInputChange} />
                      </FormGroup> */}
                      <FormGroup className="mb-2">
                        <FormLabel>Image</FormLabel>
                        <FormControl type="file" name="image" onChange={handleFileChange} />
                      </FormGroup>
                      <Button type="submit" className="mt-2">
                        Submit
                      </Button>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddNewMenu
