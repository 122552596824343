import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Form, Button, FormGroup, FormLabel, FormControl } from 'react-bootstrap'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Constants } from 'src/Constants'

const MySwal = withReactContent(Swal)

const EditMenu = () => {
  const navigate = useNavigate()
  const { menuId } = useParams()
  const [menu, setMenu] = useState({
    name: '',
    price: '',
    description: '',
    heading: '',
    image: null,
  })

  useEffect(() => {
    fetch(Constants.getMenuByID + menuId)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 1) {
          setMenu(data.menu)
        } else {
          throw new Error('Failed to fetch menu details')
        }
      })
      .catch((error) => {
        console.error('Error fetching menu details:', error)
        Swal.fire('Error', 'Failed to fetch menu details', 'error')
      })
  }, [menuId])

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setMenu((prevMenu) => ({
      ...prevMenu,
      [name]: value,
    }))
  }

  const handleImageChange = (e) => {
    const file = e.target.files[0]
    setMenu((prevMenu) => ({
      ...prevMenu,
      image: file,
    }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const formData = new FormData()
    formData.append('name', menu.name)
    formData.append('price', menu.price)
    formData.append('description', menu.description)
    formData.append('heading', menu.heading)

    if (menu.image) {
      formData.append('image', menu.image)
    }

    fetch(Constants.editMenuByID + menuId, {
      method: 'PUT',
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to update menu')
        }
        return response.json()
      })
      .then((data) => {
        Swal.fire('Success', 'Menu updated successfully', 'success')
        navigate('/menuList')
      })
      .catch((error) => {
        console.error('Error updating menu:', error)
        Swal.fire('Error', 'Failed to update menu', 'error')
      })
  }

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0 text-info">EDIT MENU</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item active text-info">Edit Menu</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="card custom-shadow rounded-lg border my-2">
                  <div className="card-body">
                    <Form onSubmit={handleSubmit}>
                      <FormGroup>
                        <FormLabel>Name</FormLabel>
                        <FormControl
                          type="text"
                          name="name"
                          value={menu.name}
                          onChange={handleInputChange}
                        />
                      </FormGroup>
                      <FormGroup>
                        <FormLabel>Price</FormLabel>
                        <FormControl
                          type="text"
                          name="price"
                          value={menu.price}
                          onChange={handleInputChange}
                        />
                      </FormGroup>
                      <FormGroup>
                        <FormLabel>Description</FormLabel>
                        <FormControl
                          as="textarea"
                          rows={3}
                          name="description"
                          value={menu.description}
                          onChange={handleInputChange}
                        />
                      </FormGroup>
                      {/* <FormGroup>
                        <FormLabel>Heading</FormLabel>
                        <FormControl
                          type="text"
                          name="heading"
                          value={menu.heading}
                          onChange={handleInputChange}
                        />
                      </FormGroup> */}
                      <FormGroup>
                        <FormLabel>Image</FormLabel>
                        <FormControl type="file" name="image" onChange={handleImageChange} />
                      </FormGroup>
                      <Button type="submit" className="mt-2">
                        Submit
                      </Button>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EditMenu
