import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col, Form, Button, Image } from 'react-bootstrap'
import documentLogo from 'src/assets/images/download.png'
import Swal from 'sweetalert2'
import { Constants, imageUrl } from 'src/Constants'
const MenuBanner = () => {
  const [menuBanner, setMenuBanner] = useState({})
  const [heading, setHeading] = useState('')
  const [file, setFile] = useState(null)
  const [loading, setLoading] = useState(false)
  // console.log(file)
  useEffect(() => {
    const fetchMenuBanner = async () => {
      try {
        const response = await fetch(
          Constants.getMenuBanner,
        )
        const data = await response.json()
        setMenuBanner(data.menuBanner)
        setFile(data.menuBanner.file)
        setHeading(data.menuBanner.heading)
      } catch (error) {
        console.error('Error fetching menu banner:', error)
      }
    }

    fetchMenuBanner()
  }, [])

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0]
    // Only update the file state if a new file is selected
    if (selectedFile) {
      setFile(selectedFile)
    }
  }
  const handleSubmit = async (event) => {
    event.preventDefault()
    setLoading(true)

    const formData = new FormData()
    formData.append('heading', heading)

    // Check if a new file is selected AND it is not null before appending
    if (file !== null && file !== undefined) {
      formData.append('file', file) // Append the new file if selected
    }

    try {
      await fetch(Constants.uploadOrUpdateMenuBanner, {
        method: 'POST',
        body: formData,
      })

      // Show success message using Swal
      Swal.fire({
        icon: 'success',
        title: 'Menu banner updated successfully',
        showConfirmButton: false,
        timer: 1500,
      })

      // Clear the file state after successful update
      setFile(null)

      // Refetch the menu banner to update the UI with the latest changes
      //   fetchMenuBanner();
    } catch (error) {
      console.error('Error updating menu banner:', error)
      // Show error message using Swal
      Swal.fire({
        icon: 'error',
        title: 'Failed to update menu banner',
        text: error.message,
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="main-content">
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 text-info">MENU BANNER</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Home</Link>
                    </li>
                    <li className="breadcrumb-item text-info active">Menu Banner</li>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="card custom-shadow rounded-lg border my-3">
                <div className="card-body">
                  <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="heading">
                      <Form.Label>Heading</Form.Label>
                      <Form.Control
                        type="text"
                        value={heading}
                        onChange={(e) => setHeading(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group controlId="file">
                      <Form.Label>File</Form.Label>
                      <Form.Control type="file" onChange={handleFileChange} />
                    </Form.Group>
                    <Button variant="primary" type="submit" disabled={loading} className="mt-2">
                      {loading ? 'Updating...' : 'Update'}
                    </Button>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <h5>Current Menu File</h5>
              <p>Heading: {menuBanner.heading}</p>
              <a
                href={`${imageUrl}${menuBanner.file}`}
                target="_blank"
              >
                <img src={documentLogo} alt="PDF Icon" style={{ width: '50px', height: '50px' }} />
              </a>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default MenuBanner
