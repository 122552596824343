import React from 'react'
import { Card, CardBody, Container } from 'react-bootstrap'

const SuccessPayment = () => {
  return (
    <Container fluid>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <Card>
          <CardBody
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '1rem',
            }}
          >
            <h5>Payment Successful</h5>
            <p>Your payment has been successfully processed.</p>
          </CardBody>
        </Card>
      </div>
    </Container>
  )
}

export default SuccessPayment
