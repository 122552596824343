import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Form, Button, Spinner } from 'react-bootstrap'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Constants } from 'src/Constants'

const MySwal = withReactContent(Swal)

const EditEvent = () => {
  const navigate = useNavigate()
  const { eventId } = useParams()
  const [loading, setLoading] = useState(true)
  const [formData, setFormData] = useState({
    heading: '',
    subheading: '',
    description: '',
    image: null,
  })

  const apiUrl = Constants.getEventDetailsByID + eventId

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const response = await fetch(apiUrl)
        if (!response.ok) {
          throw new Error('Failed to fetch event details')
        }
        const data = await response.json()
        const { heading, subheading, description } = data.event
        setFormData({ ...formData, heading, subheading, description })
      } catch (error) {
        console.error('Error fetching event details:', error)
      } finally {
        setLoading(false) // Set loading to false when data is loaded
      }
    }
  
    fetchEventDetails()
  }, [apiUrl, eventId])

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleImageChange = (e) => {
    setFormData({
      ...formData,
      image: e.target.files[0],
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      const formDataToSend = new FormData()
      formDataToSend.append('heading', formData.heading)
      formDataToSend.append('subheading', formData.subheading)
      formDataToSend.append('description', formData.description)

      // Check if the image field is not null and has been changed
      if (formData.image !== null) {
        formDataToSend.append('image', formData.image)
      }

      const response = await fetch(Constants.editEventById + eventId, {
        method: 'PUT',
        body: formDataToSend,
      })

      if (response.ok) {
        MySwal.fire({
          icon: 'success',
          title: 'Event Updated Successfully',
        })
        navigate('/eventList')
      } else {
        throw new Error('Failed to update event')
      }
    } catch (error) {
      console.error('Error updating event:', error)
      MySwal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Failed to update event!',
      })
    }
  }
  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0 text-info">EDIT EVENT</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item active text-info">Edit Event</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="card custom-shadow rounded-lg border my-2">
                  {loading ? (
                    <div className="d-flex justify-content-center align-items-center vh-100">
                      <Spinner animation="border" role="status">
                        <span className="sr-only"></span>
                      </Spinner>
                    </div>
                  ) : (
                    <div className="card-body">
                      <Form onSubmit={handleSubmit}>
                        <div className="row">
                          <Form.Group className="col-lg-6 mb-3" controlId="heading">
                            <Form.Label>Event Heading</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Name"
                              name="heading"
                              value={formData.heading}
                              onChange={handleChange}
                              required
                            />
                          </Form.Group>

                          <Form.Group className="col-lg-6 mb-3" controlId="subheading">
                            <Form.Label>Event Sub Heading</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Sub Heading"
                              name="subheading"
                              value={formData.subheading}
                              onChange={handleChange}
                            />
                          </Form.Group>

                          <Form.Group className="col-lg-6 mb-3" controlId="description">
                            <Form.Label>Event Description</Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={4}
                              placeholder="Enter Event Description"
                              name="description"
                              value={formData.description}
                              onChange={handleChange}
                            />
                          </Form.Group>

                          <Form.Group className="col-lg-6 mb-3" controlId="image">
                            <Form.Label>Event Image</Form.Label>
                            <Form.Control type="file" name="image" onChange={handleImageChange} />
                          </Form.Group>

                          <div className="text-end">
                            <Button
                              variant="outline-info"
                              className="min-btn m-2"
                              as={Link}
                              to="/eventList"
                            >
                              Cancel
                            </Button>
                            <Button variant="info" className="min-btn m-2" type="submit">
                              Save
                            </Button>
                          </div>
                        </div>
                      </Form>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EditEvent
