import React, { useState, useEffect } from 'react'
import { Row, Col, Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Constants } from 'src/Constants'
// import { Constants } from 'src/Constants'

const Dashboard = () => {
  const [menuCategoryCount, setMenuCategoryCount] = useState(0)
  const [subcategoryCount, setSubCategoryCount] = useState(0)
  const [menuCount, setMenuCount] = useState(0)
  const [eventsCount, setEventsCount] = useState(0)
  const [usersCount, setUserCount] = useState(0)
  // const [dealersCount, setDealersCount] = useState(0)
  // const [financeCount, setFinanceCount] = useState(0)

  useEffect(() => {
    fetch(Constants.getAllCategory)
      .then((response) => response.json())
      .then((data) => setMenuCategoryCount(data.categories.length))
      .catch((error) => console.error('Error fetching Vehicle Types:', error))

    fetch(Constants.getAllSubCategory)
      .then((response) => response.json())
      .then((data) => setSubCategoryCount(data.subcategories.length))
      .catch((error) => console.error('Error fetching Total Makes:', error))

    fetch(Constants.getAllMenu)
      .then((response) => response.json())
      .then((data) => setMenuCount(data.menus.length))
      .catch((error) => console.error('Error fetching Cars:', error))

    fetch(Constants.getAllEvents)
      .then((response) => response.json())
      .then((data) => setEventsCount(data.events.length))
      .catch((error) => console.error('Error fetching Cars:', error))
    fetch(Constants.getAllUsers)
      .then((response) => response.json())
      .then((data) => setUserCount(data.users.length))
      .catch((error) => console.error('Error fetching Dealers:', error))
    // fetch(Constants.getAllFinanceEnquiry)
    //   .then((response) => response.json())
    //   .then((data) => setFinanceCount(data.data.length))
    //   .catch((error) => console.error('Error fetching Dealers:', error))
  }, [])
  return (
    <div>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0 text-info">DASHBOARD </h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="text-info breadcrumb-item active">Dashboard</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="card custom-shadow rounded-lg border my-3">
                  <div className="card-body">
                    <Row className="mt-4">
                      <Col>
                        <div className="dashboard-card ">
                          <div>
                            <h5> Menu  Category</h5>
                            <div className="text-center">
                              <b style={{ color: 'black' }}>{menuCategoryCount}</b>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="dashboard-card">
                          <div>
                            <h5>Menu Sub Category</h5>
                            <div className="text-center">
                              <b style={{ color: 'black' }}> {subcategoryCount}</b>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="dashboard-card">
                          <div>
                            <h5>Menu Items</h5>
                            <div className="text-center">
                              <b style={{ color: 'black' }}> {menuCount}</b>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col>
                        <div className="dashboard-card">
                          <div>
                            <h5>Events</h5>
                            <div className="text-center">
                              <b style={{ color: 'black' }}> {eventsCount}</b>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="dashboard-card">
                          <div>
                            <h5>Users</h5>
                            <div className="text-center">
                              <b style={{ color: 'black' }}> {usersCount} </b>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        {/* <div className="dashboard-card">
                          <div>
                            <h5>Bookings</h5>
                            <div className="text-center">
                              <b style={{ color: 'black' }}> 12</b>
                            </div>
                          </div>
                        </div> */}
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="card custom-shadow rounded-lg border my-3">
                  <div className="card-body">
                    <Row className="mt-4">
                      <Col>
                        <div className="dashboard-card">
                          <Card.Body>
                            <h4>Menu Management</h4>
                            <Link to="/menuList" className="btn btn-primary">
                              Manage Menu
                            </Link>
                          </Card.Body>
                        </div>
                      </Col>
                      <Col>
                        <div className="dashboard-card">
                          <Card.Body>
                            <h4>User Management</h4>
                            <Link to={'/userList'} className="btn btn-primary">
                              Manage Users
                            </Link>
                          </Card.Body>
                        </div>
                      </Col>
                      <Col>
                        <div className="dashboard-card">
                          <Card.Body>
                            <h4>Event Management</h4>
                            <Link to={'/eventList'} className="btn btn-primary">
                              Manage Events
                            </Link>
                          </Card.Body>
                        </div>
                      </Col>
                    </Row>

                    <Row className="mt-4">
                      
                      <Col></Col>
                      <Col></Col>
                      {/* <Col>
                        <div className="dashboard-card">
                          <Card.Body>
                            <h4>Dealer Management</h4>
                            <Link to={'/customerList'} className="btn btn-primary">
                              Manage Makes
                            </Link>
                          </Card.Body>
                        </div>
                      </Col>
                      <Col>
                        <div className="dashboard-card">
                          <Card.Body>
                            <h5>Financial Management</h5>
                            <Link to={'/financeManagement'} className="btn btn-primary">
                              Manage Finance
                            </Link>
                          </Card.Body>
                        </div>
                      </Col> */}
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
