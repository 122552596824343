import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Constants } from 'src/Constants'

const MySwal = withReactContent(Swal)

const AddNewUser = () => {
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    name: '',

    password: '',
    confirmPassword: '',
    mobile: '',
    email: '',
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prevData) => ({ ...prevData, [name]: value }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (formData.password !== formData.confirmPassword) {
      MySwal.fire({
        icon: 'error',
        title: 'Passwords do not match!',
        text: 'Please make sure the passwords match.',
      })
      return
    }

    try {
      const response = await fetch(Constants.addNewUser, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: formData.name,

          password: formData.password,
          mobile: formData.mobile,
          email: formData.email,
        }),
      })

      if (response.ok) {
        MySwal.fire({
          icon: 'success',
          title: 'User Registered Successfully!',
        })

        navigate('/userList')
      } else {
        MySwal.fire({
          icon: 'error',
          title: 'Registration Failed!',
          text: 'An error occurred while registering the user.',
        })
      }
    } catch (error) {
      console.error('Error during registration:', error)
    }
  }

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0 text-info">ADD NEW USER</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item active text-info">Add New User</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="card custom-shadow rounded-lg border my-2">
                  <div className="card-body">
                    <Form onSubmit={handleSubmit}>
                      <div className="row">
                        <Form.Group className="col-lg-6 mb-3" controlId="name">
                          <Form.Label>First Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                          />
                        </Form.Group>

                        <Form.Group className="col-lg-6 mb-3" controlId="email">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Enter Email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                          />
                        </Form.Group>
                        <Form.Group className="col-lg-6 mb-3" controlId="mobile">
                          <Form.Label>Mobile</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Mobile"
                            name="mobile"
                            value={formData.mobile}
                            onChange={handleChange}
                            required
                          />
                        </Form.Group>

                        <Form.Group className="col-lg-6 mb-3" controlId="password">
                          <Form.Label>Password</Form.Label>
                          <Form.Control
                            type="password"
                            placeholder="Enter Password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            required
                          />
                        </Form.Group>
                        <Form.Group className="col-lg-6 mb-3" controlId="confirmPassword">
                          <Form.Label>Confirm Password</Form.Label>
                          <Form.Control
                            type="password"
                            placeholder="Enter Password Again"
                            name="confirmPassword"
                            value={formData.confirmPassword}
                            onChange={handleChange}
                            required
                          />
                        </Form.Group>

                        <div className="text-end">
                          <Button
                            variant="outline-info"
                            className="min-btn m-2"
                            as={Link}
                            to="/dashboard"
                          >
                            Cancel
                          </Button>
                          <Button variant="info" className="min-btn m-2" type="submit">
                            Save
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddNewUser
