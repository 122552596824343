import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Form, FormGroup, FormLabel, FormControl, Button, FormCheck } from 'react-bootstrap'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Switch from 'react-switch'
import { Constants } from 'src/Constants'

const MySwal = withReactContent(Swal)

const AddNewBanner = () => {
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    name: '',
    heading: '',
    subheading: '',
    isButton: false,
    image: null,
  })

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target
    const inputValue = type === 'checkbox' ? checked : value
    setFormData({ ...formData, [name]: inputValue })
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    setFormData({ ...formData, image: file })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    try {
      const formDataToSend = new FormData()
      formDataToSend.append('name', formData.name)
      formDataToSend.append('heading', formData.heading)
      formDataToSend.append('subheading', formData.subheading)
      formDataToSend.append('isButton', formData.isButton)
      formDataToSend.append('image', formData.image)

      const response = await fetch(Constants.createBannerList, {
        method: 'POST',
        body: formDataToSend,
      })
      const data = await response.json()
      if (data.status === 1) {
        MySwal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Banner created successfully.',
        })
        navigate('/bannerList')
      } else {
        throw new Error(data.message || 'Failed to create banner')
      }
    } catch (error) {
      console.error('Error creating banner:', error)
      MySwal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.message || 'Something went wrong!',
      })
    }
  }
  const [isButton, setIsButton] = useState(false)

  const handleSwitchChange = (checked) => {
    setFormData({ ...formData, isButton: checked })
  }

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0 text-info">ADD NEW BANNER</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Home</Link>
                      </li>
                      <li className="breadcrumb-item active text-info">Add New Banner</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="card custom-shadow rounded-lg border my-2">
                  <div className="card-body">
                    <Form onSubmit={handleSubmit}>
                      <FormGroup className="mb-3">
                        <FormLabel>Name</FormLabel>
                        <FormControl
                          type="text"
                          name="name"
                          value={formData.name}
                          onChange={handleInputChange}
                          required
                        />
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <FormLabel>Heading</FormLabel>
                        <FormControl
                          type="text"
                          name="heading"
                          value={formData.heading}
                          onChange={handleInputChange}
                          required
                        />
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <FormLabel>Subheading</FormLabel>
                        <FormControl
                          type="text"
                          name="subheading"
                          value={formData.subheading}
                          onChange={handleInputChange}
                          required
                        />
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <FormLabel>Show "Reserve Your Table" Button </FormLabel>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Switch
                            onChange={handleSwitchChange}
                            checked={formData.isButton}
                            onColor="#86d3ff"
                            onHandleColor="#2693e6"
                            handleDiameter={30}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={20}
                            width={48}
                            className="react-switch"
                            id="isButton"
                          />
                          <label htmlFor="isButton" style={{ marginLeft: '10px' }}>
                            {formData.isButton ? 'Yes' : 'No'}
                          </label>
                        </div>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <FormLabel>Image</FormLabel>
                        <FormControl type="file" name="image" onChange={handleFileChange} />
                      </FormGroup>
                      <Button type="submit" variant="info">
                        Submit
                      </Button>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddNewBanner
