import React, { useState, useEffect } from 'react'
import { FaEye, FaTrophy } from 'react-icons/fa'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Container, Row, Col, Table, Button, Modal, Form } from 'react-bootstrap'
import { Constants } from 'src/Constants'
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai'
const MySwal = withReactContent(Swal)

const UserList = () => {
  const [customers, setCustomers] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const location = useLocation()

  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [sortColumn, setSortColumn] = useState('')
  const [sortOrder, setSortOrder] = useState('asc')

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await fetch(Constants.getAllUsers)
        if (!response.ok) {
          throw new Error('Failed to fetch customers')
        }
        const data = await response.json()
        setCustomers(data.users)
      } catch (error) {
        console.error('Error fetching customers:', error.message)
      }
    }

    fetchCustomers()
  }, [])

  const deleteUserById = async (userId) => {
    try {
      const confirmResult = await MySwal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this user!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel!',
      })

      if (confirmResult.isConfirmed) {
        const response = await fetch(Constants.deleteUserById + userId, {
          method: 'DELETE',
        })

        if (!response.ok) {
          throw new Error('Failed to delete user')
        }

        setCustomers((prevCustomers) => prevCustomers.filter((customer) => customer._id !== userId))

        MySwal.fire({
          icon: 'success',
          title: 'Success',
          text: 'User deleted successfully',
        })
      }
    } catch (error) {
      console.error('Error deleting user:', error.message)

      MySwal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to delete user',
      })
    }
  }

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'))
    } else {
      setSortColumn(column)
      setSortOrder('asc')
    }
  }
  // Filter customers based on the search term
  const filteredCustomers = customers.filter((customer) =>
    Object.values(customer).some(
      (value) =>
        typeof value === 'string' && value.toLowerCase().includes(searchTerm.toLowerCase()),
    ),
  )

  const sortedCustomers = filteredCustomers.sort((a, b) => {
    const columnA = a[sortColumn]
    const columnB = b[sortColumn]

    if (columnA && columnB) {
      const valueA = typeof columnA === 'string' ? columnA : String(columnA)
      const valueB = typeof columnB === 'string' ? columnB : String(columnB)

      if (sortOrder === 'asc') {
        return valueA.localeCompare(valueB)
      } else {
        return valueB.localeCompare(valueA)
      }
    }

    return 0
  })

  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = filteredCustomers.slice(indexOfFirstItem, indexOfLastItem)

  const paginate = (pageNumber) => setCurrentPage(pageNumber)
  const [showModal, setShowModal] = useState(false)
  const [rewardData, setRewardData] = useState({
    userId: '',
    points: 0,
  })
  const handleOpenModal = async (userId) => {
    try {
      const response = await fetch(Constants.getRewardsById + userId)
      if (!response.ok) {
        throw new Error('Failed to fetch reward data')
      }
      const data = await response.json()

      if (data.status === 0) {
        // Rewards not found for the user
        setRewardData({ userId: userId, points: null })
      } else {
        // Rewards found for the user
        setRewardData(data.rewards)
      }
      setShowModal(true) // Show modal after fetching data
    } catch (error) {
      console.error('Error fetching reward data:', error.message)
    }
  }
  // Function to upload or update reward points
  const uploadOrUpdateRewardPoints = async (userId) => {
    try {
      const response = await fetch(Constants.uploadOrUpdateRewardsById + rewardData.userId, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          points: rewardData.points,
        }),
      })
      if (!response.ok) {
        throw new Error('Failed to upload or update reward points')
      }
      const data = await response.json()
      // Handle success message
      // console.log(data.message)
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Reward points  updated successfully',
      })
      setShowModal(false)
    } catch (error) {
      console.error('Error uploading or updating reward points:', error.message)
    }
  }

  return (
    <div className="main-content">
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 text-info">USER LIST</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Home</Link>
                    </li>
                    <li className="breadcrumb-item text-info active">User List</li>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="card custom-shadow rounded-lg border my-3">
                <div className="card-body">
                  <Row>
                    <Col md={4} xs={12} className="mb-3 text-center">
                      <h3 className="fw-bolder text-info mb-0">{customers.length}</h3>
                      <p className="text-secondary">Total Users</p>
                    </Col>
                  </Row>

                  <div className="dataTables_length mb-2">
                    <div className="search-box">
                      <input
                        type="text"
                        id="search"
                        placeholder="Search "
                        name="search"
                        className="form-control "
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="table-responsive">
                    <Table responsive bordered hover>
                      <thead>
                        <tr>
                          <th onClick={() => handleSort('name')}>
                            First Name
                            {sortColumn === 'name' && (
                              <span>{sortOrder === 'asc' ? ' 🔽' : ' 🔼'}</span>
                            )}
                          </th>

                          <th onClick={() => handleSort('email')}>
                            Email
                            {sortColumn === 'email' && (
                              <span>{sortOrder === 'asc' ? ' 🔽' : ' 🔼'}</span>
                            )}
                          </th>
                          <th onClick={() => handleSort('mobile')}>
                            Mobile
                            {sortColumn === 'mobile' && (
                              <span>{sortOrder === 'asc' ? ' 🔽' : ' 🔼'}</span>
                            )}
                          </th>

                          <th>Actions</th>
                        </tr>
                      </thead>

                      <tbody>
                        {currentItems?.map((customer, index) => (
                          <tr key={customer?._id}>
                            <td>{customer?.name}</td>

                            <td>{customer?.email}</td>
                            <td>{customer?.mobile}</td>

                            <td>
                              <div className="icon-container">
                                <button
                                  className="btn btn-info btn-sm me-1"
                                  onClick={() => handleOpenModal(customer._id)}
                                  title='Manage Rewards'
                                >
                                  <FaTrophy className="me-1" />
                                 
                                </button>
                                <Button
                                  variant="danger"
                                  size="sm"
                                  className="ms-1"
                                  onClick={() => deleteUserById(customer._id)}
                                >
                                  Delete
                                </Button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                  <Container>
                    <div className="col-sm-12 col-md-5">
                      <div className="dataTables_length">
                        Showing {indexOfFirstItem + 1} to{' '}
                        {Math.min(indexOfLastItem, customers.length)} of {customers.length} entries
                      </div>
                    </div>
                    <div>
                      {Array.from({
                        length: Math.ceil(customers.length / itemsPerPage),
                      }).map((_, index) => (
                        <button
                          key={index}
                          type="button"
                          className={`btn ${
                            currentPage === index + 1
                              ? 'btn-info rounded-circle text-white m-1'
                              : 'btn-outline-info m-1 btn-sm'
                          }`}
                          onClick={() => paginate(index + 1)}
                        >
                          {index + 1}
                        </button>
                      ))}
                    </div>
                  </Container>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Rewards Points</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form.Group controlId="formRewardPoints">
            <Form.Label>Points</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter points"
              value={rewardData.points}
              onChange={(e) =>
                setRewardData({ ...rewardData, points: parseInt(e.target.value) || 0 })
              }
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={uploadOrUpdateRewardPoints}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default UserList
