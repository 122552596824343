import React from 'react'
import { Link } from 'react-router-dom'
import myImage from '../../assets/deleteuser.png'
import headingLogo from '../../assets/johhny.png'

const PrivacyPolicy = () => {
    const sectionStyle = {
        backgroundColor: 'rgb(240, 240, 240)'
      };
  return (
    <div className="">
      <div className="bg-gray-100 p-4" style={sectionStyle}>
        <div className="container-fluid d-flex justify-content-between align-items-center">
          <img
            src={headingLogo}
            alt="Logo"
            className="img-fluid mr-auto"
            style={{ height: '6rem' }}
          />
          <h1 className="text-black text-xl font-bold ml-auto mr-2">Privacy Policy</h1>
        </div>
      </div>
      {/* <div className="bg-danger text-white p-4 ">
        <Link className="text-2xl  text-white">johnnytsbistroandblues@gmail.com</Link>
      </div> */}
      <div className="bg-light min-vh-100 p-2">
        <div className="  rounded-lg shadow-lg">
          {/* <h1 className=" font-weight-bold text-gray-800 mb-4">Privacy Policy</h1> */}
          <h2 className="font-weight-bold text-gray-800 mb-4">WHO WE ARE</h2>
          <p className="font-weight-bold text-dark mb-4">
            Our website address is: https://johnnytsbistroandblues.com.
          </p>
          {/* <h2 className="font-weight-bold text-gray-800 mb-4">
            WHAT PERSONAL DATA WE COLLECT AND WHY WE COLLECT IT
          </h2> */}
          <h3 className="font-weight-bold text-gray-800 mb-4">COMMENTS</h3>
        
          <p className="text-gray-700 leading-relaxed mt-4">
            When visitors leave comments on the site we collect the data shown in the comments form,
            and also the visitor’s IP address and browser user agent string to help spam detection.
            An anonymized string created from your email address (also called a hash) may be
            provided to the Gravatar service to see if you are using it. The Gravatar service
            privacy policy is available here: https://automattic.com/privacy/. After approval of
            your comment, your profile picture is visible to the public in the context of your
            comment. These app that do not access any personal and sensitive user data.
          </p>
          
         
          <h3 className="font-weight-bold text-gray-800 mb-4">MEDIA</h3>
          <p className="font-weight-bold text-dark mb-4">
            If you upload images to the website, you should avoid uploading images with embedded
            location data (EXIF GPS) included. Visitors to the website can download and extract any
            location data from images on the website.
          </p>
          {/* <h3 className="font-weight-bold text-gray-800 mb-4">CONTACT FORMS</h3>
          <p className="font-weight-bold text-dark mb-4"></p> */}
          <h3 className="font-weight-bold text-gray-800 mb-4">COOKIES</h3>
          <p className="font-weight-bold text-dark mb-4">
            If you leave a comment on our site you may opt-in to saving your name, email address and
            website in cookies. These are for your convenience so that you do not have to fill in
            your details again when you leave another comment. These cookies will last for one year.
            If you visit our login page, we will set a temporary cookie to determine if your browser
            accepts cookies. This cookie contains no personal data and is discarded when you close
            your browser. When you log in, we will also set up several cookies to save your login
            information and your screen display choices. Login cookies last for two days, and screen
            options cookies last for a year. If you select “Remember Me”, your login will persist
            for two weeks. If you log out of your account, the login cookies will be removed. If you
            edit or publish an article, an additional cookie will be saved in your browser. This
            cookie includes no personal data and simply indicates the post ID of the article you
            just edited. It expires after 1 day.
          </p>
          <h3 className="font-weight-bold text-gray-800 mb-4">
            EMBEDDED CONTENT FROM OTHER WEBSITES
          </h3>
          <p className="font-weight-bold text-dark mb-4">
            Articles on this site may include embedded content (e.g. videos, images, articles,
            etc.). Embedded content from other websites behaves in the exact same way as if the
            visitor has visited the other website. These websites may collect data about you, use
            cookies, embed additional third-party tracking, and monitor your interaction with that
            embedded content, including tracking your interaction with the embedded content if you
            have an account and are logged in to that website.
          </p>
          {/* <h3 className="font-weight-bold text-gray-800 mb-4">ANALYTICS</h3>
          <p className="font-weight-bold text-dark mb-4"></p>
          <h3 className="font-weight-bold text-gray-800 mb-4">WHO WE SHARE YOUR DATA WITH</h3>
          <p className="font-weight-bold text-dark mb-4"></p> */}
          <h3 className="font-weight-bold text-gray-800 mb-4">HOW LONG WE RETAIN YOUR DATA</h3>
          <p className="font-weight-bold text-dark mb-4">
            If you leave a comment, the comment and its metadata are retained indefinitely. This is
            so we can recognize and approve any follow-up comments automatically instead of holding
            them in a moderation queue. For users that register on our website (if any), we also
            store the personal information they provide in their user profile. All users can see,
            edit, or delete their personal information at any time (except they cannot change their
            username). Website administrators can also see and edit that information.
          </p>
          <h3 className="font-weight-bold text-gray-800 mb-4">
            WHAT RIGHTS YOU HAVE OVER YOUR DATA
          </h3>
          <p className="font-weight-bold text-dark mb-4">
            If you have an account on this site, or have left comments, you can request to receive
            an exported file of the personal data we hold about you, including any data you have
            provided to us. You can also request that we erase any personal data we hold about you.
            This does not include any data we are obliged to keep for administrative, legal, or
            security purposes.
          </p>
          <h3 className="font-weight-bold text-gray-800 mb-4">WHERE WE SEND YOUR DATA</h3>
          <p className="font-weight-bold text-dark mb-4">
            Visitor comments may be checked through an automated spam detection service
          </p>
          {/* <h3 className="font-weight-bold text-gray-800 mb-4">YOUR CONTACT INFORMATION</h3>
          <p className="font-weight-bold text-dark mb-4"></p>
          <h3 className="font-weight-bold text-gray-800 mb-4">ADDITIONAL INFORMATION</h3>
          <p className="font-weight-bold text-dark mb-4"></p>
          <h3 className="font-weight-bold text-gray-800 mb-4">HOW WE PROTECT YOUR DATA</h3>
          <p className="font-weight-bold text-dark mb-4"></p>
          <h3 className="font-weight-bold text-gray-800 mb-4">
            WHAT DATA BREACH PROCEDURES WE HAVE IN PLACE
          </h3>
          <p className="font-weight-bold text-dark mb-4"></p> */}
          {/* <h3 className="font-weight-bold text-gray-800 mb-4">
            WHAT THIRD PARTIES WE RECEIVE DATA FROM
          </h3>
          <p className="font-weight-bold text-dark mb-4"></p>
          <h3 className="font-weight-bold text-gray-800 mb-4">
            WHAT AUTOMATED DECISION MAKING AND/OR PROFILING WE DO WITH USER DATA
          </h3>
          <p className="font-weight-bold text-dark mb-4"></p>
          <h3 className="font-weight-bold text-gray-800 mb-4">
            INDUSTRY REGULATORY DISCLOSURE REQUIREMENTS
          </h3> */}
          <h3 className="font-weight-bold text-gray-800 mb-4">DELETE USER ACCOUNTS:</h3>
          <p className="font-weight-bold text-dark mb-4">
            Users who sign up within the Johhny t's Bistro & Blues Portal can request that their
            records be deleted permanently. Please note that deleting an account will also delete
            all historical records for that user permanently. The workflow for Users is supplied
            below:
          </p>
          <div className=" d-flex justify-content-center align-items-center">
            {/* Your privacy policy content here */}
            <img src={myImage} alt="Description of the image" className="img-fluid mx-auto" />
          </div>
        </div>
      </div>
      <div className="py-3 bg-white text-center">
        <div>
          <p className="font-semibold text-xl">
            &copy; Copyright {new Date().getFullYear()}, Johhny t's Bistro & Blues. All Rights
            Reserved
          </p>
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicy
