import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button, Modal, Form, Table } from 'react-bootstrap'
import { FaRegEdit, FaTrashAlt } from 'react-icons/fa'
import Swal from 'sweetalert2'

const SubscriptionPackages = () => {
  const [showAddModal, setShowAddModal] = useState(false)
  const [subscriptions, setSubscriptions] = useState([])
  const [newSubscription, setNewSubscription] = useState({
    name: '',
    description: '',
    features: '',
    price: '',
    currency: 'usd',
    interval: 'year',
  })

  useEffect(() => {
    fetchSubscriptions()
  }, [])

  const fetchSubscriptions = async () => {
    try {
      const response = await fetch('https://api.johnnytsbistroandblues.com/api/plans')
      const data = await response.json()
      console.log('Fetched data:', data)
      if (data?.data) {
        setSubscriptions(data.data)
      } else {
        console.error('Data is not in expected format:', data)
      }
    } catch (error) {
      console.error('Failed to fetch subscriptions:', error)
    }
  }

  const handleAddClose = () => setShowAddModal(false)
  const handleAddShow = () => setShowAddModal(true)

  const handleInputChange = (e) => {
    setNewSubscription({ ...newSubscription, [e.target.name]: e.target.value })
  }

  const saveSubscription = async () => {
    const response = await fetch('https://api.johnnytsbistroandblues.com/api/plans', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newSubscription),
    })

    if (response.ok) {
      Swal.fire('Success', 'Subscription package added successfully', 'success')
      fetchSubscriptions()
      handleAddClose()
      setNewSubscription({
        name: '',
        description: '',
        features: '',
        price: '',
        currency: 'usd',
        interval: 'year',
      })
    } else {
      Swal.fire('Error', 'Failed to add subscription package', 'error')
    }
  }

  const deleteSubscription = async (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this subscription package!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await fetch(`https://api.johnnytsbistroandblues.com/api/plans/${id}`, {
          method: 'DELETE',
        })

        if (response.ok) {
          Swal.fire('Deleted!', 'Your subscription package has been deleted.', 'success')
          fetchSubscriptions()
        } else {
          Swal.fire('Failed!', 'Failed to delete subscription package.', 'error')
        }
      }
    })
  }

  console.log('Subscriptions:', subscriptions)

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 text-info">SUBSCRIPTION PACKAGES</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Home</Link>
                    </li>
                    <li className="breadcrumb-item active text-info">Subscription </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div className="row m-2">
            <div className="col-xl-12">
              <div className="card custom-shadow rounded-lg border">
                <div className="card-body">
                  <div className="">
                    <div className="dataTables_length">
                      <div></div>
                      <div className="search-box">
                        <Button variant="primary" className="my-2" onClick={handleAddShow}>
                          Add New Package
                        </Button>
                        <Modal show={showAddModal} onHide={handleAddClose}>
                          <Modal.Header closeButton>
                            <Modal.Title>Add New Package</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <Form>
                              <Form.Group>
                                <Form.Label>Package Name</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="name"
                                  placeholder="Enter subscription name"
                                  value={newSubscription.name}
                                  onChange={handleInputChange}
                                />
                              </Form.Group>
                              <Form.Group>
                                <Form.Label>Package Description</Form.Label>
                                <Form.Control
                                  as="textarea"
                                  rows={4}
                                  name="description"
                                  placeholder="Enter subscription description"
                                  value={newSubscription.description}
                                  onChange={handleInputChange}
                                />
                              </Form.Group>
                              <Form.Group>
                                <Form.Label>Package Features</Form.Label>
                                <Form.Control
                                  as="textarea"
                                  rows={4}
                                  name="features"
                                  placeholder="Enter subscription features"
                                  value={newSubscription.features}
                                  onChange={handleInputChange}
                                />
                              </Form.Group>
                              <Form.Group>
                                <Form.Label>Total Price</Form.Label>
                                <Form.Control
                                  type="number"
                                  name="price"
                                  placeholder="Enter total price"
                                  value={newSubscription.price}
                                  onChange={handleInputChange}
                                />
                              </Form.Group>
                              <Form.Group>
                                <Form.Label>Interval</Form.Label>
                                <Form.Control
                                  as="select"
                                  name="interval"
                                  value={newSubscription.interval}
                                  onChange={handleInputChange}
                                >
                                  <option value="year">Year</option>
                                  <option value="month">Month</option>
                                </Form.Control>
                              </Form.Group>
                            </Form>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button variant="secondary" onClick={handleAddClose}>
                              Close
                            </Button>
                            <Button variant="primary" onClick={saveSubscription}>
                              Save Subscription
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      </div>
                    </div>

                    <Table responsive bordered hover>
                      <thead>
                        <tr>
                          <th>Package Name</th>
                          <th>Package Features</th>
                          <th>Cost</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {subscriptions?.map((product) => (
                          <tr key={product?._id}>
                            <td>{product?.name}</td>
                            <td>{product?.features}</td>
                            <td>$ {product?.price} /year </td>
                            <td>
                              <div className="icon-container">
                                <Button
                                  variant="danger"
                                  className="btn-sm"
                                  onClick={() => deleteSubscription(product?._id)}
                                >
                                  <FaTrashAlt title="Delete Customer" />
                                </Button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <div className="container"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SubscriptionPackages
