import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { FaEye, FaRegEdit, FaTrashAlt } from 'react-icons/fa'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Container, Row, Col, Table, Button, Spinner } from 'react-bootstrap'
import { Constants } from 'src/Constants'

const MySwal = withReactContent(Swal)

const BannerList = () => {
  const [banners, setBanners] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    fetchBanners()
  }, [])

  const fetchBanners = async () => {
    try {
      const response = await fetch(Constants.getAllBannerList)
      const data = await response.json()
      if (data.status === 1) {
        setBanners(data.banners)
      } else {
        throw new Error('Failed to fetch banners')
      }
      setLoading(false)
    } catch (error) {
      console.error('Error fetching banners:', error)
      setLoading(false)
    }
  }
  const handleDeleteBanner = async (bannerId) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this banner!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await fetch(Constants.deleteBannerListByID + bannerId, {
            method: 'DELETE',
          })

          if (response.ok) {
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'Banner deleted successfully.',
            })

            setBanners((prevBanners) => prevBanners.filter((banner) => banner._id !== bannerId))
          } else {
            throw new Error('Failed to delete banner')
          }
        } catch (error) {
          console.error('Error deleting banner:', error)

          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Failed to delete banner',
          })
        }
      }
    })
  }
  return (
    <div className="main-content">
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 text-info">BANNER LIST </h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Home</Link>
                    </li>
                    <li className="breadcrumb-item active text-info">Banner List</li>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="m-2">
            <Col xs={12}>
              <div className="card custom-shadow rounded-lg border">
                <div className="card-body">
                  <Row className="d-flex  justify-content-between">
                    <Col md={4} xs={12} className="mb-3 text-center">
                      <h3 className="fw-bolder text-info mb-0">{banners.length}</h3>
                      <p className="text-secondary">Total Banner Items</p>
                    </Col>
                  </Row>
                  <div>
                    <Button className="mb-3" variant="info" as={Link} to="/addNewBanner">
                      Add New Banner
                    </Button>
                    {loading ? (
                      <div className="d-flex justify-content-center align-items-center vh-50">
                        <Spinner animation="border" role="status">
                          <span className="sr-only"></span>
                        </Spinner>
                      </div>
                    ) : (
                      <Table responsive bordered hover>
                        <thead>
                          <tr>
                            <th>Banner ID</th>
                            <th>Banner Name</th>
                            <th>Button</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {banners.map((banner) => (
                            <tr key={banner._id}>
                              <td>{banner._id}</td>
                              <td>{banner.name}</td>
                              <td>{banner.isButton ? 'Yes' : 'No'}</td>
                              <td>
                                <Link
                                  to={`/editBanner/${banner._id}`}
                                  className="btn btn-info btn-sm mx-2"
                                >
                                  <FaRegEdit />
                                </Link>
                                <Button
                                  variant="danger"
                                  size="sm"
                                  onClick={() => handleDeleteBanner(banner._id)}
                                >
                                  <FaTrashAlt />
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default BannerList
